<template>
  <div class="staff">
    <div class="top">
      <!-- 热门文章配置详细页侧边栏 -->
      <span style="font-size: 18px; font-weight: 400; color: #333">
        文章列表
      </span>
      <el-divider></el-divider>
      <el-form :model="searchForm" :inline="true" ref="searchForm">
        <el-form-item label="文章标题:" prop="Title">
          <el-input
            style="width: 200px"
            v-model="searchForm.Title"
            placeholder="输入关键词搜索"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章类型:" prop="CategoryName">
          <el-select
            style="width: 150px"
            v-model="searchForm.CategoryName"
            @change="seltype"
            filterable
            placeholder="全部"
          >
            <el-option
              v-for="item in Alltype"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="列表排序:" prop="sort">
          <el-select
            style="width: 160px"
            v-model="searchForm.sort"
            @change="selectSortItem"
          >
            <el-option
              v-for="item in sortItem"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="display: block; float: left">
          <el-button @click="keysSearch(1)">
            <i class="el-icon-search" style="margin-right: 5px"></i>
            搜索
          </el-button>
          <el-button @click="resetForm('searchForm')">
            <i class="el-icon-refresh-right" style="margin-right: 5px"></i>
            重置
          </el-button>
        </el-form-item>
        <el-button
          type="primary"
          @click="Submit"
          style="float: right; margin-right: 50px;"
        >
          提交
        </el-button>
      </el-form>
    </div>
    <el-table border :data="PageConfigure" style="width: 100%">
      <el-table-column fixed="left" label="" width="140" align="center">
        <template slot-scope="scope">
          <el-checkbox  v-model="scope.row.IsCheck" @change="check(scope.row)" label="选择"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="文章标题" align="center">
        <template slot-scope="scope">
          <a
            style="color: blue; text-decoration: underline"
            :href="`https://www.1renshi.com/News/${scope.row.Id}.html`"
            target="_blank"
            ><span>{{ scope.row.Title }}</span></a
          >
        </template>
      </el-table-column>
      <el-table-column
        label="文章类型"
        width="140"
        prop="CategoryName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="创建时间"
        width="250"
        prop="DateCreated"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" width="150" label="操作" align="center">
        <template slot-scope="scope">
          <span
            class="backSpan"
            style="color: #409eff"
            @click="eduit(scope.row)"
            >编辑</span
          >
     
        </template>
      </el-table-column>
    </el-table>
    <div class="task-list-page" v-if="PageConfigure !== ''">
      <el-pagination
        @current-change="OnCurrentPageChanged"
        @size-change="handleSizeChange"
        :current-page="Params.PageIndex"
        :page-sizes="[10, 20, 30, 40, 50, 60]"
        layout="prev, pager, next,sizes,jumper,total"
        :page-size="Params.PageSize"
        background
        :total="Params.TotalCount"
      >
      </el-pagination>
    </div>

    <!-- 编辑 -->
    <div class="formdia">
      <el-dialog
        :visible.sync="dialogFormVisible"
        custom-class="edit-form"
        :title="dialongtitle"
        :modal-append-to-body="false"
      >
        <el-form :model="Rowcontent" ref="editForm">
          <el-form-item label="封面上传:" :label-width="formLabelWidth">
            <el-upload
              :show-file-list="false"
              :action="img_Url"
              :on-success="handleAvatarSuccess"
            >
              <el-button type="primary">
                上传<i class="el-icon-upload el-icon--right"></i>
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="封面地址:" :label-width="formLabelWidth">
             <el-input
              v-model="Rowcontent.ConverImageURL"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </el-form>
            
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">返回</el-button>
          <el-button @click="submitForm" :disabled="isDisabled">
            确定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name:"Articleedit",
  data() {
    return {
      dialogFormVisible: false,
      //解决重复点击问题
      isDisabled: false,
      dialongtitle: "编辑",
      IsEdit: false,
      formLabelWidth: "100px",
      PageConfigure: [], //列表分页数据
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
        ArticleId: 0,
      },
      Rowcontent: {
        Id: "",
        ConverImageURL: "",
      },
      img_Url:
        "https://file.1renshi.com/FileHandler.ashx?module=article&type=image", //封面图
      //推荐位置
      //落地页显示状态
      IsUsedLandingPageItem: [
        { value: "", label: "全部" },
        { value: true, label: "是" },
        { value: false, label: "--" },
      ],
      //列表排序
      sortItem: [
        { value: 0, label: "创建时间由近到远" },
        { value: 1, label: "创建时间由远到近" },
        { value: 2, label: "阅读量由高到低" },
        { value: 3, label: "阅读量由低到高" },
      ],
      searchForm: {
        Title: "",
        CategoryName: "",
        RecommendPosition: "",
        IsUsedLandingPage: "",
        sort: 0,
        City: "",
      },
      Alltype: [], //获取所有分类

      //查询数据
      searchContent: {},
      confirmID: [],
      SubmitId: String,
      IdDate:[],
    };
  },
  created() {
    const pageId = this.$route.query.soid;
    this.Params.ArticleId=Number(pageId);
    this.searchDate();
    this.getalltype();
    // this.refresh();
  },
  methods: {
    //刷新
    refresh() {
      this.$router.go(0);
    },

    //获取数据
    searchDate() {
      var _this = this;
      let soid=this.$route.query.soid
      this.$post(_this.$api.SearchArticleByArticleId,{
        ..._this.Params,
      })
        .then((res) => {
          console.log(res);
          _this.PageConfigure = res.Data;
          console.log(this.PageConfigure);
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
          this.$post(_this.$api.GetArticalIdList+"?article="+soid)
          .then((res)=>{
            _this.IdDate=new Set(res.Data);
            console.log(_this.IdDate);
            _this.IdDate.forEach(item=>{
              _this.confirmID.push(Number(item))
            })
              _this.PageConfigure.forEach((item,index)=>{
               if(_this.confirmID.indexOf(item.Id)!==-1){
                  item.IsCheck=true;
                }
              })
            
          })
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取所有类型
    getalltype() {
      var _this = this;
      this.$get(this.$api.GetAllArticleCategory).then((data) => {
        let res=data.Data
        for (let i = 0; i < res.length; i++) {
          let a = res[i].Name;
          let b = res[i].CategoryGuid;
          _this.Alltype.push({ label: a, value: a, id: b });
        }
      });
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.keysSearch();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.searchDate();
    },

    //查询
    seltype(val) {
      var _this = this;
      this.Alltype.forEach(function (item, index) {
        if (item.label == val) {
          console.log(item.id);
          _this.searchForm.CategoryGuid = item.id;
        }
      });
    },
    selcity(val) {
      var _this = this;
      _this.searchForm.City = val;
    },
    selectRecommend(val) {
      var _this = this;
      _this.searchForm.RecommendPosition = val;
    },
    selectIsLandingPage(val) {
      var _this = this;
      _this.searchForm.IsUsedLandingPage = val;
    },
    selectSortItem(val) {
      var _this = this;
      _this.searchForm.sort = val;
    },
    keysSearch() {
      var _this = this;
      if (
        _this.searchForm.Title != null &&
        _this.searchForm.Title.trim() != ""
      ) {
        _this.searchContent.keyWords = this.searchForm.Title;
      }
      if (
        _this.searchForm.CategoryGuid != null &&
        _this.searchForm.CategoryGuid.trim() != ""
      ) {
        _this.searchContent.category = this.searchForm.CategoryGuid;
      }
      if (
        _this.searchForm.RecommendPosition != null &&
        _this.searchForm.RecommendPosition != ""
      ) {
        _this.searchContent.recommendPosition = this.searchForm.RecommendPosition;
      }
      if (_this.searchForm.IsUsedLandingPage != null) {
        _this.searchContent.isUsedLandingPage = this.searchForm.IsUsedLandingPage;
      }
      if (_this.searchForm.sort != null && _this.searchForm.sort != "") {
        _this.searchContent.sort = this.searchForm.sort;
      }
      if (_this.searchForm.City != null && _this.searchForm.City.trim() != "") {
        _this.searchContent.city = this.searchForm.City;
      }
      // if(num==1){
      //     this.Params.PageIndex=1;
      //   }
      this.$post(this.$api.SearchArticleByArticleId, {
        ...this.searchContent,
        ...this.Params,
      }).then((res) => {
        _this.PageConfigure = res.Data;
        _this.searchContent = {};
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
        console.log(_this.PageConfigure);
        _this.PageConfigure.forEach(item => {
          if(_this.confirmID.indexOf(item.Id)!==-1){
            item.IsCheck=true
          }
        });
      });
    },
    //重置查询
    resetForm(searchForm) {
      this.$refs[searchForm].resetFields();
      this.confirmID=[];
      this.searchForm.CategoryGuid = "";
      this.searchDate();
    },

    //编辑
    eduit(val) {
      this.IsEdit = true;
      this.dialogFormVisible = true;
      this.Rowcontent.ConverImageURL = val.ConverImageURL;
      this.Rowcontent.Id = val.Id;
      console.log(val);
    },

    //编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },

    submitForm() {
      this.isDisabled = true;
      var _this=this
      this.entime();
      this.$post(
        this.$api.EditWebSideArticleCategory+"?id="+
          this.Rowcontent.Id +
          "&&converImageURL=" +
          this.Rowcontent.ConverImageURL
      )
        .then((res) => {
          if (res.IsSuccess) {
            _this.$message({
              message: "编辑成功",
              type: "success",
              duration: "1500",
            });
           _this.searchDate();
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = false;
    },

    //封面
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.Rowcontent.ConverImageURL = res.success_list[0];
      console.log(this.Rowcontent.ConverImageURL);
    },

   //选择
    check(val) {
        console.log(val.Id);
      if(val.IsCheck==true){
        console.log('check');
        this.confirmID.push(val.Id);
      }else{
        console.log('nocheck');
        let index = this.confirmID.indexOf(val.Id);
        this.confirmID.splice(index, 1);
      }
      console.log(this.confirmID);
      if(this.confirmID.length>5&&val.IsCheck==true){
        this.$message({
          message: '只会提交选取的前5个文章',
          type: 'warning',
          duration:'800'
        });

      }
    },


    //检查选择

    //提交
    Submit() {
      let a = this.confirmID.slice(0,5).toString();
      let id = a.replace(/,/g, "|");
      const listId = this.$route.query.soid;

      this.SubmitId = id;
      console.log(listId);
      console.log(id);
      this.$post(
        this.$api.EditWebSideArticle +
          "?id=" +
          listId +
          "&articleIdList=" +
          this.SubmitId
      )
        .then((res) => {
          if (res.IsSuccess) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .el-form-item {
    margin-right: 30px;
  }

  .backSpan {
    padding: 0px 10px;
    cursor: pointer;
  }

  .top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      line-height: 0px;
      font-size: 22px;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }
}
</style>